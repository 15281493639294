






























import { Component, Vue } from 'vue-property-decorator'
import { BButton, BContainer, BModal, BTable } from 'bootstrap-vue'
import logsModules from '@/store/logs'

@Component({
  components: { BTable, BContainer, BModal, BButton },
  filters: {
    print(v:Parameters<JSON['stringify']>) {
      if (v) {
        return (JSON.stringify(v))
      }
      return v
    }
  }
})
export default class Logs extends Vue {
  visible = false
  get items() {
    return logsModules.state.errors.map((value) => {
      return Object.entries(value).reduce((previousValue, [key, value]) => {
        return Object.assign(previousValue, { [key]: JSON.stringify(value) })
      }, {})
    })
  }

  showFull(message:string) {
    const h = this.$createElement
    const logItem = h('pre', {
      domProps: { textContent: message.trim() }
    })

    this.$bvModal.msgBoxOk([logItem], {
      scrollable: true,
      bodyClass: 'p-4 code',
      centered: true
    })
  }
}
